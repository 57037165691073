import React, { useState, useEffect, useContext, useRef } from 'react';
import LoadingIcon from '../Icons/LoadingIcon';
import Search from '../Booking/Search/Search';
import ResultsCard from '../Booking/Results/ResultsCard';
import Pagination from '../Pagination/Pagination';
import { BookingContext } from '../Booking/Context/BookingContext';
import { defaultObject } from '../Booking/Context/BookingDefaultData';
import { getLocalTimeZone } from '../../modules/utilities';

const BookingProvider = ({ children, geo }) => {
    if (typeof geo === 'object') {
        const timeZone = getLocalTimeZone(true, false, geo.country);

        // Need to make sure we only fetch timezone that is supported otherwise force to UTC
        if (defaultObject.supportedTimeZones.includes(timeZone)) {
            geo.timezone = timeZone;
        } else {
            geo.timezone = 'UTC';
        }

        // Will need to convert to the standard time as the times are indexed with that
        if (geo.timezone in defaultObject.standardTimeZones) {
            geo.timezone = defaultObject.standardTimeZones[geo.timezone];
        }

        defaultObject.geo = geo;
    }

    const bookingState = useState({
        ...defaultObject,
    });

    return (
        <BookingContext.Provider value={bookingState}>
            {children}
        </BookingContext.Provider>
    );
};

const SeminarListingWrapper = ({
    seminarAddictions,
    seminarType,
    seminarLocations,
    geo,
    couponCode,
    priceOverride,
    overrideText,
    overrideOriginalText,
    form
}) => {
    return (
        <BookingProvider geo={geo}>
            <SeminarListing
                seminarAddictions={seminarAddictions}
                seminarType={seminarType}
                seminarLocations={seminarLocations}
                couponCode={couponCode}
                priceOverride={priceOverride}
                overrideText={overrideText}
                overrideOriginalText={overrideOriginalText}
                form={form}
            />
        </BookingProvider>
    );
};

const SeminarListing = ({
    seminarAddictions,
    seminarType,
    seminarLocations,
    couponCode,
    priceOverride,
    overrideText,
    overrideOriginalText,
    form
}) => {
    const [bookingState, setBookingState] = useContext(BookingContext);
    const [loading, setLoading] = useState(true);
    const [firstLoading, setFirstLoading] = useState(true);
    const [optionsData, setOptionsData] = useState(null);
    const [results, setResults] = useState([]);
    const [page, setPage] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [paginationVars, setPaginationVars] = useState({});
    const resultsList = useRef(null);

    const perPage = 10;

    const fetchOnlineOptionsData = async () => {
        const search = new Search({
            type: 'bookingjourney',
            perPage: 1,
            page: 1,
            sort: '',
            distance: 0,
        });
        const response = await search.getResults();

        if (response.results.length > 0) {
            setOptionsData(response.results[0]);

            let oldData = bookingState;
            let newData = {
                ...oldData,
                data: response.results[0],
            };
            setBookingState(newData);
        }
    };

    useEffect(() => {
        fetchOnlineOptionsData();
    }, []);

    useEffect(async () => {
        if (optionsData === null) {
            return;
        }

        setLoading(true);

        if (!firstLoading && resultsList !== null) {
            resultsList.current.scrollIntoView({ behavior: 'smooth' });
        }

        const type = 'seminar';
        const addiction = seminarAddictions;
        const online = seminarType === 'both' ? null : seminarType === 'online';
        const group = type === 'seminar';
        const sort = 'soonest';
        const centreLocations = seminarLocations;
        const currency =
            'geo' in bookingState ? bookingState.geo.currency : 'GBP';
        const timeZone =
            'geo' in bookingState ? bookingState.geo.timezone : 'GMT';
        const headOfficeId = optionsData?.seminar_location_id;

        const search = new Search({
            type,
            addiction,
            online,
            group,
            perPage,
            page,
            sort,
            currency,
            timeZone,
            centreLocations,
            headOfficeId,
        });
        const response = await search.getResults();

        if (response.results.length > 0) {
            setResults(response.results);
            setTotalResults(response.total);
            setPaginationVars(response.pagination);
        }

        setLoading(false);
        setFirstLoading(false);
    }, [optionsData, page]);

    const getSeminarListingItemTitle = item => {
        if (item.addictions.length < 1) {
            return 'Seminar';
        }

        const addictionTitle =
            item.addictions[0]?.quit_title &&
            item.addictions[0]?.quit_title?.length > 0
                ? item.addictions[0]?.quit_title
                : item.addictions[0]?.title;

        if (addictionTitle.length < 1) {
            return 'Seminar';
        }

        return `${addictionTitle} Seminar`;
    };

    const handleNextChange = () => {
        if (paginationVars.current < paginationVars.total) {
            setPage(paginationVars.current + 1);
        }
    };

    const handlePrevChange = () => {
        if (
            paginationVars.current > 1 &&
            paginationVars.current <= paginationVars.total
        ) {
            setPage(paginationVars.current - 1);
        }
    };

    return (
        <div className="seminar-listing__results" ref={resultsList}>
            {loading && <LoadingIcon />}
            {!loading && results.length > 0 && (
                <>
                    {results.map((item, idx) => (
                        <ResultsCard
                            key={`product-item-${idx}`}
                            item={item}
                            type={item.online ? 'live-group' : 'group'}
                            preferType={item.online ? 'online' : 'location'}
                            titleOverride={getSeminarListingItemTitle(item)}
                            couponCode={couponCode}
                            priceOverride={priceOverride}
                            overrideText={overrideText}
                            overrideOriginalText={overrideOriginalText}
                            form={form}
                        />
                    ))}
                    <Pagination
                        perPage={perPage}
                        totalCount={totalResults}
                        onPrevChange={handlePrevChange}
                        onNextChange={handleNextChange}
                        paginationVars={paginationVars}
                        showCount={true}
                    />
                </>
            )}
        </div>
    );
};
export default SeminarListingWrapper;
